<template>
  <div class="accomplish">
    <div class="mainbody">
      <div class="mainbody-title">
        <p>代账客户入驻 - 寄送UK</p>
      </div>
      <div class="success-reg">
        <div class="success-text">
          <p class="icon-check">
            <a-icon type="check-circle" />
          </p>
          <p class="fs24">恭喜完成入驻，请及时进行UK寄送</p>
          <div class="send-text">
            <p><b>为确保代账的进行，请将您个体户的税务UK、个体户名称及密码纸的纸条</b></p>
            <p style="margin-bottom: 0;"><b>寄往：黄女士，15158812529</b></p>
            <p class="site"><b>浙江省杭州市上城区东谷创业园5楼杭州玖一再生。</b></p>
            <p class="nop">若没有领取UK的，请按步骤指引进行领取 ----- &lt;<a href="https://guangdong.chinatax.gov.cn/gdsw/mmsw_swukxlfd/2021-05/31/content_08dd6d91634a426c987757126a92185c.shtml" target="_blank">新办主体如何申领UK</a>&gt;</p>
            <p class="nop">若之前领取的是税控盘等硬件，请按步骤指引进行领取 ---- &lt;<a href="https://mp.weixin.qq.com/s/QfG53GJT2njSXvIQpwRZOA" target="_blank">税控盘等替换申领UK</a>&gt;</p>

          </div>
          <!-- <p>您也可以通过手机扫码进行签署</p> -->
        </div>
        <div class="success-btn">
          <router-link to="/">
            <div class="go-index">
              <p>进入首页</p>
            </div>
          </router-link>
          <div class="go-shop">
            <a :href="`/seller-content?entityId=${$route.query.id}`">
              <p><span style="font-size: 20px">
                  <a-icon type="user" />
                </span> 卖家中心</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      steps: 3
    };
  },
};
</script>
<style lang="less" scoped>
.accomplish {
  margin-bottom: 100px;
  max-width: 1440px;
  min-width: 990px;
  margin: 20px auto;
  @media screen and (max-width: 1440px) {
    padding: 0 15px;
  }

  .mainbody {
    background-color: #ffffff;
    position: relative;
    .mainbody-title {
      padding: 30px 0 0 50px;
      color: rgba(0, 0, 0, 0.847058823529412);
      font-size: 20px;
      font-weight: 700;
    }
    .success-reg {
      padding: 100px 0 0 0;
      text-align: center;
      .success-text {
        .fs24 {
          font-size: 24px;
          padding: 30px 0 0 0;
          color: #666666;
        }
        .fs16 {
          padding: 20px 0 0 0;
          color: #999999;
          font-size: 16px;
        }
      }
    }
    .success-btn {
      display: flex;
      margin: 0 auto;
      padding: 55px 0 72px;
      justify-content: center;
      .go-index,
      .go-shop {
        margin-left: 20px;
        width: 200px;
        font-size: 16px;
        height: 45px;
        line-height: 45px;
      }
      .go-index {
        color: #ffffff;
        background-color: rgba(26, 188, 156, 1);
        margin-left: 0;
        :hover {
          background-color: #0fce96;
          opacity: 0.8;
        }
      }
      .go-shop {
        color: #0fce96;
        outline: 1px solid #0fce96;
        :hover {
          background-color: #0fce96;
          color: #ffffff;
        }
      }
    }
  }
}
.icon-check {
  font-size: 100px;
  color: #0fce96;
}
.send-text {
  border-width: 0px;
  width: 565px;
  background: inherit;
  background-color: rgba(242, 242, 242, 1);
  border: none;
  margin: 0 auto;
  margin-top: 24px;
  font-size: 14px;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
  padding: 20px;
  font-family: '宋体';
  p {
    margin-bottom: 16px;
    line-height: 20px;
  }
  .nop {
    margin-bottom: 0;
  }
  .site {
    margin-left: 64px;
  }
}
</style>